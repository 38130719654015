<template>
  <div
    class="data-table__headers-cell"
    :style="{ width: column.width + 'px' }"
    :class="[sortBy === column.sort ? 'has-text-info' : 'has-text-grey-light']"
    :ref="column.id"
  >
    <p class="has-text-weight-bold is-size-7">
      {{ column.label }}
    </p>
    <a
      class="icon is-small"
      @click="sort(column.sort)"
      v-if="column.sort"
      :class="[
        sortBy === column.sort ? 'has-text-info' : 'has-text-grey-light'
      ]"
    >
      <i :class="['fal', getSortIcon(column.sort)]" />
    </a>
    <a class="icon is-small ml-auto" @click="openInfoModal" v-if="column.info">
      <i class="fal fa-info-circle has-text-grey-light" />
    </a>
    <span
      class="data-table__headers-resize"
      :id="column.id"
      @mousedown="mouseDownHandler"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'DataTableHeaderCell',
  data: () => ({
    minimumSize: 100,
    originalWidth: 0,
    originalX: 0,
    originalY: 0,
    originalMouseX: 0,
    originalMouseY: 0
  }),
  props: {
    column: {
      type: Object,
      required: true
    },
    sortBy: {
      type: null,
      required: true
    },
    sortOrder: {
      type: null,
      required: true
    }
  },
  methods: {
    sort(type) {
      const asc = this.sortBy !== type || !this.sortOrder
      const desc = this.sortOrder === 'asc'
      const order = asc ? 'asc' : desc ? 'desc' : null
      this.$emit('sort', { type: order ? type : null, order })
    },
    getSortIcon(column) {
      if (this.sortBy !== column || !this.sortOrder) return 'fa-sort'
      return `fa-sort-${this.sortOrder === 'desc' ? 'down' : 'up'}`
    },
    mouseDownHandler(e) {
      const element = this.$refs[this.column.id]
      this.originalWidth = parseFloat(
        getComputedStyle(element, null)
          .getPropertyValue('width')
          .replace('px', '')
      )
      this.originalX = element.getBoundingClientRect().left
      this.originalY = element.getBoundingClientRect().top
      this.originalMouseX = e.pageX
      this.originalMouseY = e.pageY

      const resize = event => {
        const width = this.originalWidth + (event.pageX - this.originalMouseX)
        if (width > this.minimumSize) {
          element.style.width = width + 'px'
          this.$emit('resizeColumn', {
            id: this.column.id,
            width
          })
        }
      }

      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', resize)
      })
    },
    openInfoModal() {
      this.$modal.open('data-table/DataTableInfo', this.column)
    }
  }
}
</script>

<style lang="sass" scoped>
.data-table__headers-resize
  position: absolute
  right: 0
  cursor: ew-resize
  height: 100%
  width: .5rem
  pointer-events: auto
</style>
